import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./About.scss";
import { urlFor, client } from "../../client";

const About = () => {
  const [abouts, setAbouts] = useState([]);

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text">
        Having a <span>Good Planning</span> <br />
        means good <span>Secure Future</span>
      </h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={about.title + index}
          >
            {/* <img src={urlFor(about.imgUrl)} alt={about.title} /> */}
            {/* <h2 className="bold-text" style={{ marginTop: 20 }}> */}
            {/* {about.title} */}
            {/* </h2> */}
            <p className="p-text" style={{ marginTop: 10 }}>
              Prelude to our Promise Immigration Services started with a clear vision of providing exceptional quality of Education and Visa assistance services to students.Our core value is to support and help students without compromising the quality and transparency. “For us, students are the priority, and genuinely caring about their career while providing personalised attention to each one of them, is something we stand by!”. The agency’s founder is a young entrepreneur. His realization of the dearth of trustworthy consultancy in the area paved the way for the foundation of “Promise Immigration Services”
            </p>
            <p className="p-text" style={{ marginTop: 10 }}>Promise Immigration Services is a rapidly growing Company that assists students in searching and applying to overseas universities through our extensive premium guidance and services in India. Our industry experience – fulfilling dreams of study abroad aspirants, building strong and credible relationships with our stakeholders including our Partner Institutions, Franchise Partners and Business Associates.
            </p> <p className="p-text" style={{ marginTop: 10 }}>
              Promise Immigration Services team of visionaries, counselors, faculty, and admission advisors provides thorough guidance for choosing the right university, doing applications, tuition fee deposits, interview preparation, visa formalities, pre-departure briefings, and travel assistance. Our team will be with you starting from the day you step into our center till the day you find yourself seated in the classroom of your chosen overseas university. You can also choose from a plethora of universities that we represent across all the world countries best suit your academic and career goals. Our many centers in many locations are equipped with state of art facilities , resources and an extensive library to assist everyone seeking education services.
            </p>

          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, "app__about"),
  "about",
  "app__whitebg"
);
