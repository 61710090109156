import React from "react";
import { AiFillInstagram } from "react-icons/ai";

const SocialMedia = () => (
  <div className="app__social">
    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/promise_immigration.services/">
      <div>
        <AiFillInstagram />
      </div>
    </a>
  </div>
);

export default SocialMedia;
