import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import springboot from "../assets/springboot.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import profile from "../assets/profile.png";
import logo from "../assets/logo.png";
import circle from "../assets/circle.svg";
import ukFlag from "../assets/ukFlag.webp";
import ausFlag from "../assets/ausFlag.jpg";
import canadaFlag from "../assets/canadaFlag.webp";

export default {
  email,
  mobile,
  springboot,
  react,
  redux,
  circle,
  logo,
  profile,
  ukFlag,
  ausFlag,
  canadaFlag
};
