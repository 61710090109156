import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: "pxij2xal",
  dataset: "production",
  apiVersion: "2022-02-01",
  useCdn: true,
  token: "skGPRrKvxwrODYGKC8ivYm5t0jixB3Kn6OSc8spqjBneuTTWIaSI2LWqA47MmnhwXvjwGlNSDUWDgMn5wSVdmhWXBHC4enMk9bQ8WT0koKuLZRZ1P7CbX4juZAEoUtg98jq14mViolFxbHgs4RVv0mgh1ET2HgxyEiNeML85pq8zlJre81pT",
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
